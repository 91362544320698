<template>
    <v-app id="inspire">
      <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="d-flex justify-center">
          <h1>Transaction Management</h1>
        </v-container>
        <template>
          <div>            
            <v-data-table
              :hide-default-header="true"
              :headers="headers"
              :items="displayList"
              :page.sync="page"
              :items-per-page="rowsPerPage"
              :loading="loading"
              hide-default-footer
              class="elevation-2 mt-4 px-4"
              :footer-props="{'items-per-page-options': [10, 20, 50]}"
            >
            <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr :colspan="headers.length">
                            <th v-for="header in headers" :key="header.text">
                                <span class="d-flex justify-center">{{header.text}}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
              <template v-slot:[`item.controls`]="{ item }">
                <!-- <span>{{ item.status }}</span> -->
                <v-btn
                  v-if="item.status === 'PAID'"
                  color="red"
                  small
                  @click="handlePaidAction(item)"
                >
                <v-icon dark color="white">mdi-refresh</v-icon>
                </v-btn>
              </template>
              <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                  Found no results.
                </v-alert>
              </template>
            </v-data-table>
            <div class="text-center">
              <v-pagination
                v-model="page"
                :length="numberOfPages"
                :total-visible="7"
              ></v-pagination>
            </div>
          </div>
        </template>
      </div>
    </v-app>
  </template>
  
  <script>
  import API from '@/api/API.js'
  import Loading from '@/views/components/Loading.vue'
  import DetailsForm from '@/views/components/DetailsForm.vue'
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'Transaction',
    components: {
      Loading,
      DetailsForm
    },
    watch: {
      page: {
        handler() {
          this.queryData();
        },
        deep: true
      }
    },
    computed: {
      //get role attributes
      ...mapGetters([
        'attributes',
        'user',
        'roles',
        'authorities'
      ]),
    },
    data() {
      return {
        headers: [],
        displayList: [],
        loading: true,
        loadingMessage: "",
        loadingDialog: false,
        loadingFinished: false,
        numberOfPages: 0,
        page: 1,
        rowsPerPage: 5,
        details: {
          title: '',
          item: {}
        },
        query: {
          code: '',
          name: ''
        },
        isShowDetails: false,
        showFormDialog: false,
        isHtml: false,
      }
    },
    async mounted() {
      this.setHeaders()
      this.queryData()
    },
    methods: {
      hasPermission(authority) {
        if (this.authorities.some(x => x.name === authority)) {
          return true
        }
        return false
      },
      async queryData(isSearch = false) {
        this.loading = true
        if (isSearch) {
          this.page = 1
        }
        let pageNumber = this.page - 1;
        try {
          const response = await API.getTransactions(pageNumber, this.rowsPerPage, this.query)
          if (!response || response.error) {
            //error getting data
            console.log(`${response.error}`)
          } else {
            this.displayList = response.transactions
            this.numberOfPages = response.pageSize
          }
          this.loading = false
        } catch (e) {
          console.log(e)
          this.loading = false
        }
      },
      closeDialog(type) {
        console.log(type)
        if (type === 'details') {
          this.details.title = ''
          this.details.item = {}
          this.isShowDetails = false
        } else if (type === 'CREATED' || type === 'UPDATE') {
          this.showFormDialog = false
        } else if (type === 'loading') {
          this.loadingDialog = false
          this.$router.go(0);
        } else if (type === 'loading-error') {
          this.loadingDialog = false
        }
      },
      view(item) {
        this.details.title = item.display_name
        this.details.item = item
        this.isShowDetails = true
      },
      search(event, type) {
        this.query[type] = event
      },
      setHeaders() {
        var headers = [{
          text: 'Reference #',
          value: 'referenceNumber',
          align: 'center',
        },
        {
          text: 'Merchant Code',
          value: 'merchantCode',
          align: 'center',
        },
        {
          text: 'Payment Option',
          value: 'paymentOption',
          align: 'center'
        },
        {
          text: 'Type',
          value: 'type',
          align: 'center'
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'center'
        },
        {
          text: 'Mobile Number',
          value: 'mobileNumber',
          align: 'center'
        },
        {
          text: 'Date Created',
          value: 'dateCreated',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'controls',
          sortable: false
        }]
        this.headers = headers
      },
      handlePaidAction(item) {
      console.log("Paid action clicked for:", item);
      
      // Reset loading dialog states before starting the new action
      this.loadingMessage = `Updating ${item.merchantCode}: ${item.referenceNumber}`;
      this.isHtml = true;
      this.loadingDialog = true;  // Show the loading dialog
      this.loadingFinished = false;  // Reset loading finished state

      setTimeout(async () => {
        try {
          // Call retryTransactions with the item (parameters)
          const updateResponse = await API.retryTransactions(item);
          
          // Mark loading as finished
          this.loadingFinished = true;

          // Handle API response
          if (updateResponse.error && updateResponse.error.includes(':')) {
            const errorMessage = updateResponse.error.split(':')[1].trim();
            console.log("RESPONSE LOG", errorMessage);
            this.loadingMessage = errorMessage;
            this.isHtml = false;
          } else {
            console.log("RESPONSE LOG", updateResponse.message); 
            this.loadingMessage = `${updateResponse.message} Updating ${item.merchantCode}: ${item.referenceNumber}`;
            this.isHtml = false;
          }

          // Refresh data table
          this.queryData();  // Re-fetch the data after the update

        } catch (error) {
          console.error("Error during API call:", error);
          this.loadingMessage = "An error occurred while updating the merchant.";
          this.isHtml = false;
        }
      }, 2000);
    }
  }
};
  </script>
  
  <style scoped>
  .v-data-table {
  max-width: 100%;
  }
  </style>
  